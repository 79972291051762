var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            staticClass: "p-0",
            class: {
              "container-layout": _vm.$screen.width >= 992,
              "container-mobile": _vm.$screen.width < 992,
            },
          },
          [
            _c(
              "b-row",
              { staticClass: "form" },
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "h1",
                      {
                        class: [
                          `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                          ,
                          { "is-pwa": _vm.$isPwa() },
                        ],
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(231, "Exports")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "b-card",
                      { staticClass: "pb-0", attrs: { "no-body": "" } },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "6" } },
                              [
                                _c(
                                  "b-card",
                                  { attrs: { "no-body": "" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            10,
                                            "Export type:"
                                          ),
                                          "label-for": "exportType",
                                          "label-cols": 4,
                                        },
                                      },
                                      [
                                        _c("b-form-select", {
                                          attrs: {
                                            size: "md",
                                            id: "exportType",
                                            options: _vm.optionsForExports,
                                          },
                                          on: { change: _vm.getType },
                                          model: {
                                            value: _vm.exportType,
                                            callback: function ($$v) {
                                              _vm.exportType = $$v
                                            },
                                            expression: "exportType",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.showDates
                                      ? _c(
                                          "b-card",
                                          [
                                            _vm.exportType === 6 ||
                                            _vm.exportType === 7 ||
                                            _vm.exportType === 1 ||
                                            _vm.exportType === 11
                                              ? _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        203,
                                                        "Department"
                                                      ),
                                                      "label-cols": 12,
                                                      "label-cols-md": 4,
                                                      "label-for":
                                                        "new-expense_ticket-department",
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-select", {
                                                      attrs: {
                                                        id: "new-expense_ticket-department",
                                                        options:
                                                          _vm.mapDepartments,
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.departmentChange,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.selectedDepartment,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.selectedDepartment =
                                                            $$v
                                                        },
                                                        expression:
                                                          "selectedDepartment",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.exportType === 1 ||
                                            _vm.exportType === 11
                                              ? _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        208,
                                                        "Function"
                                                      ),
                                                      "label-cols": 12,
                                                      "label-cols-md": 4,
                                                      "label-for":
                                                        "new-function",
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-select", {
                                                      attrs: {
                                                        id: "new-function",
                                                        options:
                                                          _vm.mapFunctions,
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.functionChange,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.selectedFunction,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.selectedFunction =
                                                            $$v
                                                        },
                                                        expression:
                                                          "selectedFunction",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.exportType === 1 ||
                                            _vm.exportType === 11
                                              ? _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        209,
                                                        "User"
                                                      ),
                                                      "label-cols": 12,
                                                      "label-cols-md": 4,
                                                    },
                                                  },
                                                  [
                                                    _c("v-select", {
                                                      attrs: {
                                                        options: _vm.users,
                                                        label: "text",
                                                        reduce: (option) =>
                                                          option.value,
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            210,
                                                            "Select user ..."
                                                          ),
                                                      },
                                                      model: {
                                                        value: _vm.selectedUser,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.selectedUser = $$v
                                                        },
                                                        expression:
                                                          "selectedUser",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.exportType === 11 &&
                                            _vm.isDeterProject === true
                                              ? _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        298,
                                                        "Session teams"
                                                      ),
                                                      "label-cols": 12,
                                                      "label-cols-md": 4,
                                                    },
                                                  },
                                                  [
                                                    _c("v-select", {
                                                      attrs: {
                                                        options:
                                                          _vm.sessionOptions,
                                                        label: "text",
                                                        multiple: "",
                                                        reduce: (option) =>
                                                          option.value,
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            289,
                                                            "Select session team"
                                                          ),
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.sessionTeamsSelected,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.sessionTeamsSelected =
                                                            $$v
                                                        },
                                                        expression:
                                                          "sessionTeamsSelected",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.exportType != 11
                                              ? _c(
                                                  "div",
                                                  [
                                                    this.initDates
                                                      ? _c(
                                                          "b-form-group",
                                                          {
                                                            attrs: {
                                                              label:
                                                                _vm.FormMSG(
                                                                  11,
                                                                  "From:"
                                                                ),
                                                              "label-for":
                                                                "from",
                                                              "label-cols": 4,
                                                            },
                                                          },
                                                          [
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                type: "date",
                                                                id: "from",
                                                                required: "",
                                                              },
                                                              model: {
                                                                value: _vm.from,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.from =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "from",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "b-form-group",
                                                      {
                                                        attrs: {
                                                          label: _vm.FormMSG(
                                                            12,
                                                            "To:"
                                                          ),
                                                          "label-for": "to",
                                                          "label-cols": 4,
                                                        },
                                                      },
                                                      [
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            type: "date",
                                                            id: "to",
                                                            required: "",
                                                          },
                                                          model: {
                                                            value: _vm.to,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.to = $$v
                                                            },
                                                            expression: "to",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        class: `${
                                          _vm.$screen.width < 768
                                            ? "d-flex flex-column"
                                            : "d-flex justify-content-end"
                                        }`,
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            class: `${
                                              _vm.$screen.width < 768
                                                ? "col-sm-6 mb-2"
                                                : "mr-3"
                                            }`,
                                          },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  block: !_vm.$screen.md,
                                                  variant: "outline-primary",
                                                },
                                                on: {
                                                  click: _vm.downloadExport,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      this.FormMSG(
                                                        20,
                                                        "Download"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            class: `${
                                              _vm.$screen.width < 768
                                                ? "col-sm-6"
                                                : ""
                                            }`,
                                          },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  block: !_vm.$screen.md,
                                                  variant: "outline-primary",
                                                },
                                                on: {
                                                  click: _vm.sendExportByEmail,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      this.FormMSG(
                                                        21,
                                                        "Send by email"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("loading", {
                          attrs: {
                            active: _vm.isLoading,
                            "is-full-page": true,
                          },
                          on: {
                            "update:active": function ($event) {
                              _vm.isLoading = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("script-export-modal", {
              attrs: {
                items: _vm.scripts,
                custom: _vm.customReport,
                "export-type": _vm.exportTypeStr,
              },
              on: {
                "script-export-modal:loading": _vm.handleScriptLoading,
                "script-export-modal:closed": _vm.handleScriptRerpotOnClosed,
              },
              model: {
                value: _vm.isScriptReportOpen,
                callback: function ($$v) {
                  _vm.isScriptReportOpen = $$v
                },
                expression: "isScriptReportOpen",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }